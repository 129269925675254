<template>
  <el-dialog title="编辑供货门店" v-model="dialogVisible" width="70%" :before-close="handleClose">
    <el-form :model="updateRowMsg" ref="formRef">
      <el-form-item label="供货门店" label-width="120px">
        <FilterShop @changeStore="changeStore" :storeData="updateRowMsg.storeCodeList" />
      </el-form-item>
      <el-form-item label="下发将来门店">
        <el-radio-group v-model="updateRowMsg.isEnable">
          <el-radio :label="1"
            >自动供货
            <el-cascader
              ref="casRef"
              v-model="updateRowMsg.isSalesRegion"
              :options="areaOptions"
              clearable
              filterable
              @change="changeArea()"
              :show-all-levels="false"
              collapse-tags
              :props="areaProps"
            ></el-cascader>
          </el-radio>
          <el-radio :label="0">非自动供货</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleCommit">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import commonApi from '@/api'
import API from '../service/api'
import { ref, defineEmit, reactive, nextTick, onMounted, defineProps } from 'vue'
import FilterShop from './filter-shop.vue'
import { ElMessage, ElMessageBox } from 'element-plus'

const props = defineProps({
  propData: '',
  isNew: {
    type: Boolean,
    default: false,
  },
})

const updateRowMsg = reactive({
  storeCodeList: [],
  isSalesRegion: [],
  isEnable: 1,
})

onMounted(() => {
  getAreas()
  querySupplierStoreInfo()
})

const querySupplierStoreInfo = async () => {
  if (!props.isNew) {
    let res = await API.querySupplierStoreInfo({ supplierCode: props.propData })
    updateRowMsg.storeCodeList = res.data.storeCodeList
    updateRowMsg.isEnable = res.data.isEnable
    updateRowMsg.isSalesRegion = res.data.isSalesRegion
  } else {
    let res = await API.queryStoreCode({ supplierCode: props.propData })
    updateRowMsg.storeCodeList = res.data?.storeCodeList || []
    updateRowMsg.isEnable = res.data?.isEnable
    updateRowMsg.isSalesRegion = res.data?.isSalesRegion || []
  }
}

const areaOptions = ref([])
const areaProps = reactive({
  label: 'name',
  value: 'code',
  children: 'children',
  checkStrictly: true,
  multiple: true,
})
//获取区域数据
const getAreas = () => {
  //获取门店区域
  const getTreeData = data => {
    for (var i = 0; i < data.length; i++) {
      if (data[i].children.length < 1) {
        // children若为空数组，则将children设为undefined
        data[i].children = undefined
      } else {
        // children若不为空数组，则继续 递归调用 本方法
        getTreeData(data[i].children)
      }
    }
    return data
  }
  commonApi.getNewArea().then(res => {
    areaOptions.value = getTreeData(res.data)
  })
}
const casRef = ref()
const changeArea = () => {
  updateRowMsg.isSalesRegion = casRef.value.getCheckedNodes().map(item => item.data.code)
}

/**
 * 改变门店
 * @param {*} data
 * isAll:是否全选 true全选 false全不选：需要将之前加的数据删除
 */
const changeStore = ({ isAll, data = [], isOneClick }) => {
  if (isOneClick) {
    updateRowMsg.storeCodeList = data
    return
  }
  if (isAll) {
    data.forEach(item => {
      let index = updateRowMsg.storeCodeList.findIndex(i => i === item)
      if (index === -1) {
        updateRowMsg.storeCodeList.push(item)
      }
    })
  } else {
    data.forEach(item => {
      let index = updateRowMsg.storeCodeList.findIndex(i => i === item)
      updateRowMsg.storeCodeList.splice(index, 1)
    })
  }
  nextTick(() => {
    formRef.value.clearValidate('storeCodeList')
  })
}
/**
 * 父容器传来的自定义事件
 */
const emit = defineEmit(['update-dialogVisible'])

const dialogVisible = ref(true)

/**
 * 关闭dialog弹出框
 */
const handleClose = () => {
  emit('update-dialogVisible', false)
}

const formRef = ref(null)
const rules = {}

/**
 * 保存
 */
const handleCommit = async () => {
  try {
    await formRef.value.validate()
    let params = {
      ...updateRowMsg,
      isSalesRegion: updateRowMsg.isEnable == 1 ? updateRowMsg.isSalesRegion : [],
      supplierCode: props.propData,
    }
    let res
    if (props.isNew) {
      res = await API.batchSaveThirdPartySupplierStore(params)
    } else {
      res = await API.newSaveBingSupplierStore(params)
    }

    if (res.code === '200') {
      ElMessage.success('操作成功')
    } else {
      ElMessage.error(res.msg)
    }
    emit('update-dialogVisible', { update: false, search: true })
  } catch (error) {
    ElMessage.warning('请完成必要信息填写')
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-cascader {
  width: 280px;
  .el-tag--light {
    max-width: 150px;
  }
}
::v-deep .el-cascader__search-input {
  min-width: 20px !important;
}
.remark {
  color: rosybrown;
}
.coupon-table {
  margin-top: 5px;
}
.el-input-number {
  width: 100%;
}
::v-deep(.el-input__inner) {
  text-align: left;
}
.timer-toast {
  margin-left: 5px;
  color: rosybrown;
}
.coupou-item {
  display: flex;
  border: 1px solid #ccc;
  margin: 10px;
  .left {
    display: flex;
    flex-direction: column;
    width: 100px;
    border-right: 1px solid #ccc;
    justify-content: center;
  }
  .right {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 10px;
  }
}

.el-pagination {
  text-align: right;
  margin-top: 10px;
}
</style>
